export const decodeJWT = (token) => {
  const [header, payload, signature] = token.split(".");

  const headerDecoded = Buffer.from(header, "base64").toString("utf8");

  const jsonPayload = Buffer.from(payload, "base64").toString("utf8");

  return {
    header: JSON.parse(headerDecoded),
    payload: JSON.parse(jsonPayload),
    signature,
  };
};
